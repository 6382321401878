import {Row,Col, Container} from 'react-bootstrap';
import img1 from '../assets/j1.webp';
import img2 from '../assets/j2.webp';
import img3 from '../assets/j3.webp';
import img4 from '../assets/j4.webp';
import img5 from '../assets/j5.webp';
import img6 from '../assets/j6.webp';
import img7 from '../assets/j7.webp';
import img8 from '../assets/j8.webp';
import img9 from '../assets/j9.webp';

const Services=()=>{
    const content = [
        {
          imgSrc: img1,
          title: 'Door Supervision',
          text: "Door supervision involves managing entry and exit points of venues such as nightclubs, bars, and events. Door supervisors are responsible for checking IDs, ensuring that only authorized individuals gain access, and managing any disturbances or conflicts that arise. Their role is critical in maintaining order and safety within a venue, ensuring compliance with licensing laws, and protecting both patrons and property.",
        },
        {
          imgSrc: img2,
          title:' Manned Guarding',
          text: "Manned guarding involves the presence of trained security personnel at a particular location, such as a business premises, residential area, or construction site. These guards provide a visible deterrent to potential criminal activity, conduct regular patrols, monitor CCTV, and respond to any incidents that may occur. Manned guarding is essential for maintaining a secure environment and can be tailored to the specific needs of the client.",
        },
        {
        imgSrc: img3,
        title: 'Event Security',
        text: 'Event security services focus on maintaining safety and order during events such as concerts, festivals, sports games, or corporate gatherings. This service includes crowd control, access management, emergency response, and coordination with local authorities if necessary. Event security teams are trained to handle large groups of people, manage potential risks, and ensure that the event runs smoothly without security issues.',
      },
      {
        imgSrc: img4,
        title: ' Spectator Safety Management',
        text: "Spectator safety management is a specialized service designed to protect the safety of individuals attending events, particularly in stadiums or large venues. This service involves risk assessment, crowd management strategies, and the deployment of stewards and security personnel to ensure that spectators are safe, that the event adheres to safety regulations, and that any incidents are managed quickly and effectively.",
      },
      {
        imgSrc: img5,
        title: ' Static Guard and Dog Security',
        text: "Static guard services involve assigning security personnel to a fixed location, such as a gatehouse, reception area, or specific point of entry. These guards are responsible for monitoring and controlling access, conducting security checks, and maintaining a secure environment. Dog security involves using trained security dogs alongside guards to enhance security measures. The presence of security dogs can be a significant deterrent to potential intruders and can assist in detecting threats such as explosives or drugs.",
      },
      {
        imgSrc: img6,
        title: 'Temporary Traffic Management',
        text: 'Temporary traffic management involves planning and implementing strategies to control vehicle and pedestrian traffic in specific areas, typically during events, construction projects, or roadworks. This service ensures that traffic flows smoothly and safely, minimizing disruption and reducing the risk of accidents. Traffic management may include setting up road signs, barriers, and traffic lights, as well as directing traffic manually when necessary.',
      },
      {
        imgSrc: img7,
        title: 'Key Holding',
        text: "Key holding services involve a security company holding keys to a client’s property and being responsible for responding to any security issues, such as alarms or emergencies, outside of normal business hours. This service provides peace of mind to property owners, as the security team can quickly access the property to deal with any incidents, perform inspections, and secure the premises."
      }
      ,
      {
        imgSrc: img8,
        title: 'Alarm Response',
        text: "Alarm response services involve a security company responding to alarm activations at a client’s property. When an alarm is triggered, the security team is dispatched to the location to investigate the cause, whether it’s a false alarm or a real threat. They are trained to handle a variety of situations, secure the premises, and liaise with the police or emergency services if needed."
      }
      ,
      {
        imgSrc: img9,
        title: 'Ad-hoc Services',
        text: "Ad-hoc services refer to security services that are provided on a flexible, as-needed basis. These services can include short-term security for special events, temporary guard coverage for specific situations, or any other customized security solution that meets a client’s immediate or unique needs. The flexibility of ad-hoc services allows clients to access professional security support when required, without long-term commitments."
      }
    ]

    const titleStyle = {
        fontFamily: 'Josefin Sans',
        margin: '2rem 0 0 0',
         fontSize: '51px'
      };
    
      const textStyle = {
        fontFamily: 'Josefin Sans',
        fontStyle: 'normal',
        fontSize: '22px'
      };
    return(
      <div>
        <section>
            <Container>
               <div className='py-3 d-flex justify-content-center flex-column align-items-center'>
                  <h1 style={{fontFamily: 'Josefin Sans'}}><span style={{fontSize: '60px'}}>You Get Every Type Of Security</span><br /><span style={{color: '#0668FB',fontSize: '56px'}}>Our Service</span> </h1>
                 
               </div>
                  {content.map((item, index) => (
        <Row key={index} className="my-2 custom-row">
          <Col md={6} xs={12} className={`d-flex justify-content-center order-1 order-md-${index % 2 === 0 ? '1' : '2'}`}>
            <img src={item.imgSrc} alt={item.title} width='372px' height='257px' />
          </Col>
          <Col md={6} xs={12} className={`order-2 order-md-${index % 2 === 0 ? '2' : '1'} d-flex flex-column align-items-left justify-content-center`}>
            <h3 style={titleStyle}>{item.title}</h3>
            <p style={textStyle}>{item.text}</p>
          </Col>
        </Row>
      ))}                 
            </Container>            
        </section>
       
        </div>
    )
}
export default Services;