import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';

const ContactForm = () => {
  const [formStatus, setFormStatus] = useState(null); 
  const [isSubmitting, setIsSubmitting] = useState(false); 

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true); // Set submitting state to true

    const form = event.target;
    const formData = new FormData(form);

    formData.append("access_key", "a4532f50-ea13-445b-a5b1-0863feaafa08");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: json
      }).then((res) => res.json());

      if (res.success) {
        setFormStatus({ type: 'success', message: 'Your message has been sent successfully!' });
        form.reset(); 
      } else {
        setFormStatus({ type: 'error', message: 'There was an error submitting your form. Please try again.' });
      }
    } catch (error) {
      setFormStatus({ type: 'error', message: 'An unexpected error occurred. Please try again later.' });
    } finally {
      setIsSubmitting(false); 
    }
  };

  return (
    <section id="contact" className="py-5" style={{ background: '#D9D9D9' }}>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center mb-4">
            <h1>Contact Us!</h1>
          </div>
          <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            {formStatus && (
              <Alert variant={formStatus.type === 'success' ? 'success' : 'danger'}>
                {formStatus.message}
              </Alert>
            )}
            <Form onSubmit={onSubmit}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="tel"
                  name="phone"
                  placeholder="Phone"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  as="textarea"
                  name="message"
                  placeholder="Message"
                />
              </Form.Group>
              <div className="text-center">
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
