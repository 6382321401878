import React from 'react';
import A1 from '../assets/A1.webp';

const AboutPage=()=>{
    return(
        <>
        <section className='about-banner'>
            <div className="container-fluid px-0">
                <img className="img-fluid" src={A1} alt="about hero img"/></div>
        </section>
        <section className='py-5'>
            <div className="container">
                <div className="row">
                    <div className='pb-3'>
                    <h1 style={{color: '#0668FB'}}>Who We Are</h1>
                    <p>At <b> PROTECTORS SECURITY SERVICES LTD</b>, we believe that safety is more than just a service—it’s a commitment to the communities we serve. Founded by a team of seasoned security professionals, we have built our company on the principles of trust, vigilance, and dedication to providing the highest level of protection. Our mission is to safeguard what matters most to you, whether it’s your business, property, or peace of mind.</p>
                    <h1 style={{color: '#0668FB'}}>Our Journey</h1>
                    <p>We started our journey with a vision: to create a security company that goes beyond the conventional. With over 14 years combined experience in the security industry, our founders noticed a gap in the market for a truly customer-centric security service that prioritizes personalized solutions over a one-size-fits-all approach.
                    From humble beginnings, our team has grown to include some of the most skilled and highly trained security professionals in the industry. We come from diverse backgrounds. Together, we have shaped PROTECTORS SECURITY SERVICES LTD into a leader in the UK’s security landscape, providing a robust and adaptive security solution for every need.
                    </p>
                    <h1 style={{color: '#0668FB'}}>Our Values</h1>
                    <p><b>Integrity :</b> We operate with the highest standards of honesty and professionalism.<br/>
                    <b>Responsiveness :</b> We are always ready, always alert, and always one step ahead.<br/>
                    <b>Community :</b> We are deeply invested in the safety and well-being of the communities we serve. <br/>
                    <b>Innovation :</b> We embrace new technologies and methods to continuously improve our service
</p>
                    </div>
                    <div className='pb-3'>
                        <h1 style={{color: '#0668FB'}}>Our Mission</h1>
                        <p>To provide unparalleled security services that ensure the safety, protection, and peace of mind of our clients. We are committed to delivering innovative, reliable, and responsive security solutions tailored to meet the unique needs of each client, ensuring a secure environment for businesses, individuals, and communities.</p>
                    </div>
                    <div>
                        <h1 style={{color: '#0668FB'}}>Our Vision</h1>
                        <p> To be the leading provider of security services, recognized for our excellence, integrity, and commitment to creating safe and secure environments. We strive to set the standard in the security industry by continuously enhancing our services, embracing cutting-edge technology, and fostering a culture of professionalism and accountability among our team. Our vision is to make a significant and positive impact on the safety and security of the communities we serve.</p>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default AboutPage;