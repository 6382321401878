import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavigationBar from './components/NavigationBar';
import Homepage from './components/HomePage';
import AboutPage from "./components/AboutPage";
import ServicePage from "./components/ServicePage";
import ContactPage from "./components/ContactPage";
import Footer from './components/Footer';
import './App.css';


function App() {
  return (
    <Router>
    <div className="App">
      <NavigationBar/>
      <Routes>
      <Route path="/" element={<Homepage/>}/>
      <Route path="/about" element={<AboutPage/>}/>
      <Route path="/service" element={<ServicePage/>}/>
      <Route path="/contact" element={<ContactPage/>}/>
      
      
      </Routes>
      <Footer/>
    </div>
    </Router>
  );
}

export default App;
