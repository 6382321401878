import { Container, Nav, Navbar } from 'react-bootstrap';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../assets/protector-logo.png';
import './NavigationBar.css';

const NavigationBar = () => {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();

  return (
    <>
      <Navbar bg="light" expand="lg" expanded={expanded}>
        <Container>
          <Navbar.Brand as={Link} to="/"><img src={Logo} alt="logo" width="48px"/></Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(!expanded)}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link 
                as={Link} 
                to="/" 
                className={`nav-link-custom ${location.pathname === '/' ? 'active' : ''}`}
              >
                Home
              </Nav.Link>
              <Nav.Link 
                as={Link} 
                to="/about" 
                className={`nav-link-custom ${location.pathname === '/about' ? 'active' : ''}`}
              >
                About
              </Nav.Link>
              <Nav.Link 
                as={Link} 
                to="/service" 
                className={`nav-link-custom ${location.pathname === '/service' ? 'active' : ''}`}
              >
                Service
              </Nav.Link>
              <Nav.Link 
                as={Link} 
                to="/contact" 
                className={`nav-link-custom ${location.pathname === '/contact' ? 'active' : ''}`}
              >
                Contact
              </Nav.Link>
            </Nav>
            <span>
              <Link to="/contact">
                <button className="btn bg-black text-light">Enquire Now</button>
              </Link> 
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavigationBar;
