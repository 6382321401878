import { Container, Row, Col, Card } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { StarFill } from "react-bootstrap-icons";
import './Review.css'; 
const reviews = [
  {
    name: "Jessica T",
    text: "Protectors Security Services exceeded our expectations in every way. Their professionalism, responsiveness, and attention to detail were second to none. From the initial consultation to the implementation of our security systems, the entire team was thorough and dedicated. We feel safer and more secure knowing they have our back.",
    rating: 5,
  },
  {
    name: "Robert M.",
    text: "We have been using Protectors Security Services for the past two years, and their service has been outstanding. Their security personnel are highly trained and always vigilant. They’ve provided us with peace of mind and have been instrumental in ensuring the safety of our premises. Highly recommended!",
    rating: 5,
  },
  {
    name: "Laura K.",
    text:"I can't say enough good things about Protectors Security Services. Their quick response times and expert advice have made a significant difference in our operations. Their staff is professional and courteous, and their security systems are top-notch. It's reassuring to know we can rely on them for our security needs.",
    rating: 5,
  },
  {
    name: "Daniel H.",
    text: "From day one, Protectors Security Services has been exceptional. Their team is knowledgeable and dedicated, always going above and beyond to ensure our safety. They provided a customized security plan that perfectly fits our needs, and their ongoing support has been invaluable. We trust them completely.",
    rating: 5,
  },
  {
    name: "Emily R.",
    text: "The service provided by Protectors Security Services has been exemplary. Their attention to detail and proactive approach to security have made a significant impact on our operations. They’re not just a service provider; they’re a partner in ensuring our safety and security.",
    rating: 5,
  },
  {
    name: "Michael S.",
    text: "Protectors Security Services has been a game-changer for us. Their team is responsive, professional, and genuinely cares about our security. Their technology is state-of-the-art, and their customer service is exceptional. It’s clear that they are experts in their field.",
    rating: 5,
  },
  
  
];

const ReviewCard = ({ name, text, rating }) => {
  return (
    <Col className="review-card" style={{marginBottom: '3rem'}}>
      <Card className="h-100">
        <Card.Body className="card-body">
          <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center'}}>
        <div style={{display: 'flex' ,flexDirection: 'column'}}>
          <div className="text-center mb-3">            
            <h5 className="mt-2 mb-0" style={{marginLeft: '2px'}}>{name}</h5>
          </div>          
          </div>          
          </div>
          <div className="d-flex justify-content-center mb-3" >
            <ul style={{ listStyle: "none", display: "flex", padding: 0 }}>
              {Array(rating).fill().map((_, i) => (
                <li key={i}>
                  <StarFill style={{ color: "#f2d202", marginRight: '4px' }}/>
                </li>
              ))}
            </ul>
          </div>
          <Card.Text style={{ font: "13px", fontFamily: "Margarine sans-serif", fontWeight: "400", fontStyle: "normal", marginBottom: '2rem' }}>
            {text}
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

const Review = () => {
  return (
    <section className="py-3" id="review">
      <Container className="review-container">
        <div className="course-title text-center">
          
        <h1 style={{color: 'rgb(6, 104, 251)'}}>What People Say About Us</h1> 
        </div>
        <Carousel fade indicators={true} className="review-carousel">
          {reviews.map((review, index) => (
            <Carousel.Item key={index}>
              <Row className="justify-content-center">
                <ReviewCard name={review.name} text={review.text} rating={review.rating} />
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </section>
  );
};
export default Review;