import React from 'react';
import ContactForm from './ContactForm';
import C1 from '../assets/C1.webp';
const ContactPage=()=>{
    return(<>
    <section className='contact-banner'>
        <div className="container-fluid px-0">
            <img src={C1} alt="contact hero img" className="img-fluid" />
        </div>
    </section>
    <section className='py-5'>
        <div className="container">
            <div className="row">
                <h1>We Are Always There For Your Security</h1>
                <h3 style={{color: '#0668FB'}}>Proctectors Security Serives Ltd</h3>
                <h5>Best Security Providers</h5>
                <div>
                    <h2 style={{color: '#0668FB'}}>Lets Talk Security</h2>
                    <p>At <b>PROTECTORS SECURITY SERVICES LTD</b>, your safety is our priority. Contact us today to find out how we can provide a security solution that fits your needs.
                    </p>
                </div>
            </div>

        </div>
    </section>
    <ContactForm/>
    </>
    )
}
export default ContactPage;