import React from 'react'
import Services from './Services';
import S1 from '../assets/S1.webp';
const ServicePage=()=>{
    return(<>
    <section className='service-banner'>
        <div className="containe-fluid py-0">
            <img className="img-fluid" src={S1} alt="service hero img" />
        </div>
    </section>
    <Services/>
    
    </>

    )
}
export default ServicePage;